<template>
  <div class="blogs">
    <div class="content-header">
      <h1>博客管理<small>查看博客</small></h1>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/' }">后台管理</el-breadcrumb-item>
        <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        <el-breadcrumb-item>查看博客</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--中间内容-->
    <div class="app-container">
      <div class="box">
        <div class="filter-container">
          <el-form ref="form" :model="blogSearchForm" label-width="100px" inline="true">
            <el-form-item label="博客标题：" prop="blogName">
              <el-input v-model="blogSearchForm.title" placeholder="请输入博客标题"></el-input>
            </el-form-item>
            <el-form-item prop="archive" label="归档：">
              <el-select v-model="blogSearchForm.archive" placeholder="请选择归档状态">
                <el-option
                  v-for="item in archiveList"
                  :key="item.id"
                  :label="item.archiveName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="typeId" label="分类：">
              <el-select v-model="blogSearchForm.typeIds" placeholder="请选择分类" multiple>
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item prop="tagId" label="标签：">
                          <el-select v-model="blogSearchForm.tagIds" multiple placeholder="请选择标签">
                            <el-option
                              v-for="item in tagList"
                              :key="item.id"
                              :label="item.tagName"
                              :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>-->
            <el-form-item prop="tagId" label="完成状态：">
              <el-select v-model="blogSearchForm.flag" placeholder="请选择状态">
                <el-option
                  v-for="item in blogFlag"
                  :key="item.id"
                  :label="item.flagName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="tagId" label="发布状态：">
              <el-select v-model="blogSearchForm.published" placeholder="请选择状态">
                <el-option
                  v-for="item in publishStatus"
                  :key="item.id"
                  :label="item.publishName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="listBlogs()">查询</el-button>
              <el-button type="warning" v-on:click="resetSearch()">重置</el-button>
              <el-button type="primary" class="butT" @click="createBlog()">编写</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-table size="middle" current-row-key="id" :data="blogList" stripe highlight-current-row>
            <el-table-column prop="id" min-width="15px" align="center" label="主键" v-if="false"></el-table-column>
            <el-table-column prop="title" label="博客标题" align="center"></el-table-column>
            <el-table-column prop="content" label="内容" align="center" v-if="false"></el-table-column>
            <el-table-column label="主图" align="center">
              <template slot-scope="scope">
                <img v-bind:src="scope.row.firstPictureUrl" alt="图片加载失败" title="博客主图" width="100%"/>
              </template>
            </el-table-column>
            <el-table-column label="完成状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.flag === blogType.RELEASE.CODE" v-text="blogType.RELEASE.MSG"></p>
                <p v-if="scope.row.flag === blogType.DRAFT.CODE" v-text="blogType.DRAFT.MSG"></p>
              </template>
            </el-table-column>
            <el-table-column prop="thumbs" label="点赞数" align="center"></el-table-column>
            <el-table-column label="发布状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.published === PUBLISH_STATUS.PUBLISHED.CODE"
                   v-text="PUBLISH_STATUS.PUBLISHED.MSG"></p>
                <p v-if="scope.row.published === PUBLISH_STATUS.UNPUBLISHED.CODE"
                   v-text="PUBLISH_STATUS.UNPUBLISHED.MSG" style="color: red"></p>
              </template>
            </el-table-column>
            <el-table-column label="归档状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.archive === BLOG_OPERATE_STATUS.YES.CODE" v-text="BLOG_OPERATE_STATUS.YES.MSG"></p>
                <p v-if="scope.row.archive === BLOG_OPERATE_STATUS.NO.CODE" v-text="BLOG_OPERATE_STATUS.NO.MSG"
                   style="color: red"></p>
              </template>
            </el-table-column>
            <el-table-column label="赞赏状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.appreciation === BLOG_OPERATE_STATUS.YES.CODE"
                   v-text="BLOG_OPERATE_STATUS.YES.MSG"></p>
                <p v-if="scope.row.appreciation === BLOG_OPERATE_STATUS.NO.CODE" v-text="BLOG_OPERATE_STATUS.NO.MSG"
                   style="color: red"></p>
              </template>
            </el-table-column>
            <el-table-column label="评论状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.commentable === BLOG_OPERATE_STATUS.YES.CODE"
                   v-text="BLOG_OPERATE_STATUS.YES.MSG"></p>
                <p v-if="scope.row.commentable === BLOG_OPERATE_STATUS.NO.CODE" v-text="BLOG_OPERATE_STATUS.NO.MSG"
                   style="color: red"></p>
              </template>
            </el-table-column>
            <el-table-column label="版权状态" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.shareStatement === SHARE_STATEMENT.ORIGINAL.CODE"
                   v-text="SHARE_STATEMENT.ORIGINAL.MSG" style="color: green"></p>
                <p v-if="scope.row.shareStatement === SHARE_STATEMENT.REPRINT.CODE"
                   v-text="SHARE_STATEMENT.REPRINT.MSG"></p>
                <p v-if="scope.row.shareStatement === SHARE_STATEMENT.TRANSLATE.CODE"
                   v-text="SHARE_STATEMENT.TRANSLATE.MSG"></p>
              </template>
            </el-table-column>
            <el-table-column prop="views" label="浏览次数" align="center"></el-table-column>
            <el-table-column label="类型" align="center">
              <template slot-scope="scope">
                <p v-text="scope.row.type.typeName"></p>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="creator.nickname" label="作者" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center" v-if="false"></el-table-column>
            <el-table-column label="管理" align="center" min-width="150px">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="deleteBlog(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="updateBlog(scope.row)">编辑</el-button>
                <!--              <el-button size="mini" type="info" @click="handleForbidden(scope.row)">禁用</el-button>-->
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              class="pagiantion"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[5,10,15]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>

        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blogType: this.$constant.BLOG_FLAG, // 博客编辑状态
      PUBLISH_STATUS: this.$constant.PUBLISH_STATUS, // 博客发布状态
      BLOG_OPERATE_STATUS: this.$constant.BLOG_OPERATE_STATUS,  // 博客推荐赞赏评论状态
      SHARE_STATEMENT: this.$constant.SHARE_STATEMENT,  // 博客发布类型

      pagination: { // 分页相关模型数据
        page: 1, // 当前页码
        size: 5, // 每页显示的记录数
        total: 0, // 总记录数
      },
      blogSearchForm: {
        title: '',
        typeIds: '',
        tagIds: [],
        flag: '',
        published: '',
        archive: ''
      },
      operateBlogFormData: {  // 添加表单数据
        id: '',
        blogName: ''
      },
      typeList: [],
      tagList: [],
      blogFlag: [{
        id: 0,
        flagName: '完结'
      },
        {
          id: 1,
          flagName: '草稿'
        }],
      archiveList: [{
        id: 0,
        archiveName: '是'
      },
        {
          id: 1,
          archiveName: '否'
        }],
      publishStatus: [{
        id: 0,
        publishName: '已发布'
      },
        {
          id: 1,
          publishName: '未发布'
        }],

      blogList: [], // 当前页要展示的分页列表数据
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      rules: { // 校验规则
        // 分类名称
        blogName: [
          {required: true, message: '请输入博客名称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.getTagList()
    this.getTypeList()
    this.listBlogs()
    // this.getUser()
  },
  methods: {

    // 获取所有的分类并回显
    async getTypeList() {
      const res = await this.$http.get('/type/listTypes')
      this.typeList = res.data.list
    },
    // 获取所有的标签并回显
    async getTagList() {
      const res = await this.$http.get('/tag/listTags')
      this.tagList = res.data.list
    },

    // 分页查询
    async listBlogs() {
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        page: this.pagination.page,
        size: this.pagination.size,
        title: this.blogSearchForm.title,
        typeIds: this.blogSearchForm.typeIds.toLocaleString(),
        tagIds: this.blogSearchForm.tagIds.toLocaleString(),
        flag: this.blogSearchForm.flag,
        published: this.blogSearchForm.published,
        archive: this.blogSearchForm.archive
      }
      const res = await this.$http.get('/blog/listBlogs', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        this.$message.error('获取博客列表失败！')
      }

      this.pagination.total = res.data.total;
      this.blogList = res.data.list
    },

    createBlog() {
      this.$router.push("PostBlogs");
    },
    resetSearch() {
      this.blogSearchForm.title = '';
      this.blogSearchForm.tagIds = [];
      this.blogSearchForm.typeIds = [];
      this.blogSearchForm.flag = '';
      this.blogSearchForm.published = '';
      this.blogSearchForm.archive = '';
      this.listBlogs();
    },

    updateBlog(rowData) {
      // 跳转对应路由携带参数
      this.$router.push({
        path: 'PostBlogs',
        query: rowData
      })
    },

    /*    getUser() {
          this.user = window.sessionStorage.getItem('user')
          this.nickname = JSON.parse(this.user).nickname
          this.avatar = JSON.parse(this.user).avatar
        },*/
    // 切换页码
    handleCurrentChange(currentPage) {
      // 设置最新的页码
      this.pagination.page = currentPage
      // 重新调用listBlogs方法进行分页查询
      this.listBlogs()
    },
    handleSizeChange(newSize) {
      this.pagination.size = newSize
      this.listBlogs()
    },

    deleteBlog(rowData) {
      this.$confirm('确定删除该博客嘛？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete("/blog/deleteBlog", {id: rowData.id});
        if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.listBlogs();
        } else {
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/style.css";
@import "../../../assets/css/me.css";

.blogs {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
